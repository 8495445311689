//Added file for github issue #273
import React, { useState } from "react";
import { useStyles } from "./styles";
import {
  Grid,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead
} from "@material-ui/core";
import { useAppDispatch } from "app/store";
import { Colors } from "common/Colors";
import Xsquare from "assets/bt-assets/x-square.svg";
// import LoadSpinner from "common/LoadSpinner";
import { tableStyle } from "features/devices-page/device-settings/device-settings-tabs/tableStyles";
import DeleteReportModal from "./DeleteReportModal";
import {
  setSuccessModalOpen,
  setSuccessModalDescription
} from "components/success-modal/successModalSlice";
import { setRecallReportList } from "features/drawer/drawerSlice";
import Button from "components/common-buttons/Button";
import { devicesAPI } from "services/devicesAPI";
import LoadSpinner from "common/LoadSpinner";

export interface ReportsTableProps {
  allReportsList: any[];  //report list for issue #273
  tenantId: string;
  handleUpdateModal: Function;  //for calling the update modal with report id for issue #273
}

const ReportsTable: React.FC<ReportsTableProps> = ({ allReportsList, tenantId, handleUpdateModal }) => {
  const classes = useStyles();
  const table = tableStyle();
  const dispatch = useAppDispatch();

  const [reportId, setReportId] = useState("");
  const [reportName, setReportName] = useState("");
  const [deleteReportModal, setDeleteReportModal] = useState(false);
  const [loading, setLoading] = useState(false);

  // for displaying the selected day for issue #273
  const handleScheduleDays = (days: number) => {
    switch (days) {
      case 0:
        return (
          <span>Daily</span>
        );
      case 1:
        return (
          <span>Weekly</span>
        );
      case 2:
        return (
          <span>Monthly</span>
        );
      default:
        break;
    }
  };

  // for displaying the selected time for issue #273
  const handleScheduleHours = (hours: number) => {
    switch (hours) {
      case 0:
        return (
          <span>00:00</span>
        );
      case 1:
        return (
          <span>01:00</span>
        );
      case 2:
        return (
          <span>02:00</span>
        );
      case 3:
        return (
          <span>03:00</span>
        );
      case 4:
        return (
          <span>05:00</span>
        );
      case 5:
        return (
          <span>05:00</span>
        );
      case 6:
        return (
          <span>06:00</span>
        );
      case 7:
        return (
          <span>07:00</span>
        );
      case 8:
        return (
          <span>08:00</span>
        );
      case 9:
        return (
          <span>09:00</span>
        );
      case 10:
        return (
          <span>10:00</span>
        );
      case 11:
        return (
          <span>11:00</span>
        );
      case 12:
        return (
          <span>12:00</span>
        );
      case 13:
        return (
          <span>13:00</span>
        );
      case 14:
        return (
          <span>14:00</span>
        );
      case 15:
        return (
          <span>15:00</span>
        );
      case 16:
        return (
          <span>16:00</span>
        );
      case 17:
        return (
          <span>17:00</span>
        );
      case 18:
        return (
          <span>18:00</span>
        );
      case 19:
        return (
          <span>19:00</span>
        );
      case 20:
        return (
          <span>20:00</span>
        );
      case 21:
        return (
          <span>21:00</span>
        );
      case 22:
        return (
          <span>22:00</span>
        );
      case 23:
        return (
          <span>23:00</span>
        );
      default:
        break;
    }
  };

  // for displaying the selected timezone for issue #273
  const handleScheduleTimezone = (timezone: number) => {
    switch (timezone) {
      case 0:
        return (
          <span>EST</span>
        );
      case 1:
        return (
          <span>CST</span>
        );
      case 2:
        return (
          <span>MST</span>
        );
      case 3:
        return (
          <span>PST</span>
        );
      case 4:
        return (
          <span>AST</span>
        );
      case 5:
        return (
          <span>HST</span>
        );
      default:
        break;
    }
  };

  // for testing the report right away for issue #273
  const handleTestButton = async (report: any) => {
    try {
      setLoading(true);
      const testReport = await devicesAPI.testReport(tenantId, report.report_id);
      setLoading(false);
      dispatch(setSuccessModalOpen(true));
      dispatch(setSuccessModalDescription(testReport));
    } catch (error) {
      setLoading(false);
      alert(error);
      console.log(error);
    }
  };

  // for calling delete report modal for issue #273
  const deleteReportHandler = (index: number) => {
    setReportId(allReportsList[index]?.report_id);
    setReportName(allReportsList[index]?.report_name)
    setDeleteReportModal(true);
  };

  // to close delete report modal for issue #273
  const closeModals = () => {
    setDeleteReportModal(false);
    dispatch(setRecallReportList(true));
  };

  // to display users of the report in the table for issue #273
  const displayRecipents = (recipent: any) => {

    return recipent && recipent?.length !== 0 ? (
      <span className={classes.role}>
        {recipent.map((user: any) => user?.user_name)
          .join("; ")}
      </span>
    ) : (
      <span className={classes.role}>No user(s)</span>
    );
  };

  return loading ? (
    <LoadSpinner size="90vh" />
  ) : (
    <Grid container className={classes.root}>
      <Grid item xs={12}>
        <TableContainer style={{ height: 500 }}>
          <Table
            style={{ width: "100%" }}
            size="small"
            aria-label="a dense table"
          >
            <TableHead>
              <TableRow>
                <TableCell className={table.header} align="left">
                  Report Name
                </TableCell>
                <TableCell className={table.header} align="left">
                  Status
                </TableCell>
                <TableCell className={table.header} align="left">
                  Recipents
                </TableCell>
                <TableCell className={table.header} align="left">
                </TableCell>
                <TableCell className={table.header} align="left">
                  Schedule
                </TableCell>
                <TableCell className={table.header} align="left">
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {allReportsList?.map(
                (report: any, index: number) => {
                  return (
                    <TableRow key={index}>
                      <TableCell
                        className={table.tableCell}
                        align="left"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          handleUpdateModal(report);
                        }}
                      >
                        {report?.report_name}
                      </TableCell>



                      <TableCell
                        className={table.tableCell}
                        align="left"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          handleUpdateModal(report);
                        }}
                      >
                        {!report || report.report_error === undefined ? (
                          <span className={classes.lightGrayPill}>N/A</span>
                        ) : report.report_error !== 0 ? (
                          <span className={classes.redPill}>Error</span>
                        ) : (
                          <span className={classes.greenPill}>OK</span>
                        )}
                      </TableCell>
                      <TableCell
                        className={table.tableCell}
                        align="left"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          handleUpdateModal(report);
                        }}
                      >
                        {displayRecipents(report?.users)}
                      </TableCell>
                      <TableCell
                        className={table.tableCell}
                        align="left"
                        style={{ cursor: "pointer" }}
                      >
                        <Button
                          type="button"
                          color="defaultTheme"
                          onClick={() => {
                            handleTestButton(report)
                          }}
                        >
                          Test
                        </Button>
                      </TableCell>
                      <TableCell
                        className={table.tableCell}
                        align="left"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          handleUpdateModal(report);
                        }}
                      >
                        {handleScheduleDays(Number(report?.report_schedule_days))}-{handleScheduleHours(Number(report?.report_schedule_hours))}{" "}{handleScheduleTimezone(Number(report?.report_schedule_tz))}
                      </TableCell>
                      <TableCell
                        className={table.tableCell}
                        style={{ padding: "0 2em" }}
                        align="left"
                      >
                        <img
                          src={Xsquare}
                          alt="Delete firmware"
                          style={{
                            color: Colors.BasinRed,
                            cursor: "pointer"
                          }}
                          onClick={() => { deleteReportHandler(index) }}
                        />
                      </TableCell>
                    </TableRow>
                  );
                }
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      {deleteReportModal && (
        <DeleteReportModal
          tenantId={tenantId}
          reportId={reportId}
          reportName={reportName}
          closeModals={closeModals}
        />
      )}
    </Grid>
  );
};
export default ReportsTable;
