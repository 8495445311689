/* Issue fixed for github issue #24, issue #66, issue #88*/
import React, { useState, useEffect, useRef } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { UserAccountsProps } from "./types";
import { useDispatch, useSelector } from "react-redux";
import { useAppSelector } from "app/store";
import { RootState } from "app/rootReducer";
import { Colors } from "common/Colors";
import { setSelectedAccount } from "app/appSlice";
import { AccountObject } from "app/types";
import SuperAdminMenuItems from "./role-based-menu-items/SuperAdminMenuItems";
import AdminMenuItems from "./role-based-menu-items/AdminMenuItems";
import EngineerMenuItems from "./role-based-menu-items/EngineerMenuItems";
import NonAdminMenuItems from "./role-based-menu-items/NonAdminMenuItems";
import {
  setDeviceSettingsActive,
  setCurrentDeviceId,
  setSavedConfigurationedActive
} from "features/drawer/drawerSlice";
import { useHistory } from "react-router-dom";
import AccountAPI from "services/accountsAPI";
import clsx from "clsx";
import { setPathway } from "features/real-time-status-page/RealTimeStatusPageSlice";

/* added handleSignOut function to send data from drawer to here and from here to SuperAdminMenuItems,NonAdminMenuItems,
 AdminMenuItems and EngineerMenuItems for logout functionality for responsive UI issue #24  */
export type MenuItemsProps = {
  pageName: string;
  handleSignOut: Function;
  setPageName: Function;
};

export const MenuItems: React.FC<MenuItemsProps> = ({
  pageName,
  handleSignOut, //added this for logout functionality for responsive UI issue #24
  setPageName
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const gettingTenantsRef = useRef<boolean>(false);

  const [userAccounts, setUserAccounts] = useState<UserAccountsProps[]>([]);

  // const access_level: string = "admin"; // !temporary mock access_level
  // const role: string = "admin"; // !temporary role
  const {
    access_level, //! when this is active, switch it out with mock access_level
    accounts
  } = useSelector((state: RootState) => state.AppSlice.userInformation);
  const { superAdminAccountId } = useAppSelector(state => state.AppSlice);
  const selectedAccountId = useSelector(
    (state: RootState) => state.AppSlice?.selectedAccount?.accountId || ""
  );

  const selectedAccountUserRole = useSelector(
    (state: RootState) => state.AppSlice?.selectedAccount?.role || ""
  );

  // const { accountId, role } = useSelector(
  //   (state: RootState) => state.AppSlice.selectedAccount
  // );

  const handleDeviceReset = () => {
    dispatch(setCurrentDeviceId(null));
    dispatch(setDeviceSettingsActive(false));
    dispatch(setSavedConfigurationedActive(false));
    history.push("/admin-devices");
  };

  const handleSelectAccount = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { tenant_name = "", tenant_id = "", roles = [""], active = false } =
      accounts.filter(
        (account: any) =>
          account.tenant_id === e.target.value && account.active === true
      )[0] || {};
    dispatch(
      setSelectedAccount({
        account: {
          accountName: tenant_name,
          accountId: tenant_id,
          role: roles[0],
          active: active || false
        }
      })
    );
    // Added line to clear out the data on Breadcrumbs and reset the dashboard for issue #88
    dispatch(setPathway([]));
    if (
      access_level === "general" &&
      ["admin", "power_user"].indexOf(roles[0]) > -1 &&
      pageName === "Devices"
    ) {
      handleDeviceReset();
    }

    if (
      access_level === "general" &&
      [
        "office_user",
        "field_operations",
        "field_view_only",
        "notifications"
      ].indexOf(roles[0]) > -1 &&
      pageName === "Devices"
    ) {
      history.push("/");
    }
  };

  const handleSuperAdminAccounts = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    // console.log(e.target.value, userAccounts);
    const { accountName, accountId } = userAccounts.filter(
      (account: any) => account.accountId === e.target.value
    )[0];
    dispatch(
      setSelectedAccount({
        account: {
          accountName: accountName,
          accountId: accountId,
          role: selectedAccountUserRole,
          active: true
        }
      })
    );

    dispatch(setPathway([])); // resets asset dashboard bread crumbs on account change

    if (pageName === "Devices") {
      handleDeviceReset();

    }
    // Issue 393 redirect from reports page when account is changed
    if (pageName === "Reports") {
      history.push("/asset-status");
    }
  };

  const displaySelectBasedOnRole = () => {
    switch (
    access_level // ! real value: access_level
    ) {
      case "admin":
      case "engineer":
        // ! if access_level is admin - do api call here to populate select list and populate redux accounts
        return (
          <div className={classes.accountInput}>
            <select
              value={selectedAccountId}
              className={classes.select}
              onChange={handleSuperAdminAccounts}
            >
              {userAccounts.map(
                ({
                  accountName,
                  accountId
                }: {
                  accountName: string;
                  accountId: string;
                }) => {
                  return (
                    <option
                      key={accountName + accountId}
                      value={accountId}
                      selected
                    >
                      {accountName}
                    </option>
                  );
                }
              )}
            </select>
          </div>
        );
      // case "engineer":
      //   // ! if access_level is engineer - do api call here to populate select list and populate redux accounts
      //   return (
      //     <div className={classes.accountInput}>
      //       <select
      //         value={accountId}
      //         className={classes.select}
      //         onChange={handleSuperAdminAccounts}
      //       >
      //         {userAccounts.map(
      //           ({
      //             accountName,
      //             accountId,
      //           }: {
      //             accountName: string;
      //             accountId: string;
      //           }) => {
      //             return (
      //               <option
      //                 key={accountName + accountId}
      //                 value={accountId}
      //                 selected
      //               >
      //                 {accountName}
      //               </option>
      //             );
      //           }
      //         )}
      //       </select>
      //     </div>
      //   );
      case "general":
        return (
          <div className={classes.accountInput}>
            <select
              value={selectedAccountId}
              className={classes.select}
              onChange={handleSelectAccount}
            >
              {userAccounts.map(
                ({
                  accountName,
                  accountId
                }: {
                  accountName: string;
                  accountId: string;
                }) => {
                  return (
                    <option
                      key={accountName + accountId}
                      value={accountId}
                      selected
                    >
                      {accountName}
                    </option>
                  );
                }
              )}
            </select>
          </div>
        );
      default:
        break;
    }
  };

  useEffect(() => {
    const retrieveAllAccounts = async () => {
      const accounts = await AccountAPI.getAllTenants();
      let _userAccounts = accounts.map((account: any) => {
        return {
          accountName: account.tenant_name,
          accountId: account.tenant_id,
          role: "admin",
          active: account.active || true
        };
      });

      const superAdminAccountIndex = _userAccounts.findIndex((account: any) => {
        return account.accountId === superAdminAccountId;
      });
      _userAccounts.push(..._userAccounts.splice(0, superAdminAccountIndex));

      setUserAccounts(_userAccounts);
      dispatch(
        setSelectedAccount({ account: _userAccounts[0], initializing: true })
      );
    };

    if (
      (access_level === "admin" || access_level === "engineer") &&
      !gettingTenantsRef.current // prevent duplicate API due to 2 dependencies firing below
    ) {
      // console.log("INSIDE BASINTRAK ROUTE");
      gettingTenantsRef.current = true;
      retrieveAllAccounts();
    }

    if (access_level === "general" && accounts.length > 0) {
      // console.log("CUSTOMER ROUTE", accounts);
      const _userAccounts = accounts
        .map((account: AccountObject) => {
          return {
            accountName: account.tenant_name,
            accountId: account.tenant_id,
            role: account.roles[0] || "",
            active: account.active || false
          };
        })
        .filter((account: any) => account.active === true);
      const safeUserAccount = _userAccounts[0] || {
        accountName: "",
        accountId: "",
        role: "",
        active: false
      };
      // .filter((account: any) => account.active === true);
      setUserAccounts(_userAccounts);
      dispatch(
        setSelectedAccount({ account: safeUserAccount, initializing: true })
      );
    }
  }, [dispatch, accounts, access_level]);

  return (
    <div>
      <div
        className={clsx({
          [classes.disableInput]: pageName === "Accounts"
        })}
      >
        {displaySelectBasedOnRole()}
      </div>
      {/* {role === "notifications" ? null : ( */}
      {selectedAccountUserRole === "notifications" ||
        userAccounts.length < 1 ? null : (
        <>
          <SuperAdminMenuItems
            setPageName={setPageName}
            handleSignOut={handleSignOut} //added for passing data from here to SuperAdminMenuItems for responsive UI issue #24
            accessLevel={access_level}
          />
          <EngineerMenuItems
            setPageName={setPageName}
            handleSignOut={handleSignOut} //added for passing data from here to EngineerMenuItems for responsive UI issue #24
            accessLevel={access_level}
          />
          <AdminMenuItems
            setPageName={setPageName}
            handleSignOut={handleSignOut} //added for passing data from here to AdminMenuItems for responsive UI issue #24
            accessLevel={access_level}
            role={selectedAccountUserRole}
          />
          <NonAdminMenuItems
            setPageName={setPageName}
            handleSignOut={handleSignOut} //added for passing data from here to NonAdminMenuItems for responsive UI issue #24
            accessLevel={access_level}
            role={selectedAccountUserRole}
          />
        </>
      )}
    </div>
  );
};

export default MenuItems;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    accountName: {
      fontFamily: "Rajdhani",
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: 18,
      color: "#FFFFFF",
      paddingLeft: 10
    },
    active: {
      color: theme.palette.primary.light,
      fontWeight: 600,
      backgroundColor: "#FAFAFA",
      borderRadius: "30px 0 0 30px",
      pointerEvents: "none"
    },
    accountInput: {
      margin: "2rem 3rem 0 2.5rem"
    },
    select: {
      border: `1px solid ${Colors.LightGray}`,
      backgroundColor: Colors.MainColor, //changed color to slate for issue #66
      width: "100%",
      padding: ".3rem .5rem .3rem 1rem",
      color: Colors.White,
      // fontWeight: "bold",
      fontSize: "18px",
      "& option": {
        color: theme.palette.primary.light,
        backgroundColor: Colors.White
        // fontWeight: "bold",
      },
      cursor: "pointer"
    },
    disableInput: {
      opacity: 0.5,
      pointerEvents: "none"
    }
  })
);
