/* Issue fixed for github issue #124,issue #138,issue #187,issue #205,issue #226,issue #251 */
import React, { useState, useEffect } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import CloseIcon from "@material-ui/icons/Close";
import Modal from "@material-ui/core/Modal";
import { useSelector } from "react-redux";
import { RootState } from "app/rootReducer";
import { Grid } from "@material-ui/core";
import Button from "components/common-buttons/Button";
// import ViewParameter from "features/devices-page/device-settings/device-settings-tabs/parameter-settings/view-edit-parameter/ViewParameter";
import { Colors } from "common/Colors";
import clsx from "clsx";
import { useForm } from "react-hook-form";
import { DeviceParametersProps } from "features/devices-page/device-settings/types";
import {
  handleParameterSource,
  handleParameterDecimal,
  handleDeviceLogRate,
  handleInputsLogRate
} from "./AssetParameterHelper";

type ViewParameterModalProps = {
  parameterIndex: number;
  assetParameterList: DeviceParametersProps[];
  open: boolean;
  handleClose: () => void;
};

export const ViewParameterModal: React.FC<ViewParameterModalProps> = ({
  parameterIndex,
  assetParameterList,
  open,
  handleClose
}) => {
  const classes = useStyles();

  const {
    sensor_min,
    high_value,
    analog_digital_range,
    high_high_to_max_color,
    low_value,
    source,
    display_type,
    low_low_to_low_color,
    sensor_max,
    low_to_high_color,
    // device_parameter_id,
    display_in_view,
    min_to_low_low_color,
    source_input,
    text_decoding,
    parameter_name,
    min_value,
    unit,
    high_to_high_high_color,
    low_low_value,
    decimals,
    high_high_value,
    log_rate,
    max_value,
    display_in_summary,
    suspect_parameter_name,
    select_parameter,
    request_type,
    modbus_adress,
    modbus_adressing,
    offset,
    parameter_number,
    parameter_type,
    read_function_code,
    scalling,
    write_function_code,
    modules,
    application_index,
    application_max,
    application_min,
    application_parameter_type,
    perform_every,
    pid_parameter,
    select_pid_setpoint,
    p_gain,
    i_gain,
    d_gain,
    p_max,
    p_min,
    i_max,
    i_min,
    d_max,
    d_min,
    select_analytics_operation,
    window_size,
    p_min_rate,
    p_max_rate,
    parameter_tag
  } = assetParameterList[parameterIndex];

  const handleColor = (colorName: string) => {
    switch (colorName) {
      case "red":
        return (
          <span
            className={classes.circle}
            style={{ backgroundColor: Colors.Red }}
          />
        );
      case "yellow":
        return (
          <span
            className={classes.circle}
            style={{ backgroundColor: Colors.Yellow }}
          />
        );
      case "green":
        return (
          <span
            className={classes.circle}
            style={{ backgroundColor: Colors.Green }}
          />
        );
      default:
        break;
    }
  };

  const handleCloseViewParametersModal = () => {
    handleClose();
  };



  return (
    <Modal
      open={open}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div className={classes.modalOverlay}>
        <form>
          <div className={classes.modalBody}>
            <div className={classes.header}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div
                  className={clsx(classes.headerTab, classes.selectedHeader)}
                >
                  <p className={classes.headerText}>View parameter</p>
                </div>
              </div>
              <CloseIcon
                className={classes.icon}
                onClick={handleCloseViewParametersModal}
              />
            </div>
            <div className={classes.inputBody}>
              {






                <Grid container className={classes.root}>
                  <Grid item xs={12} className={classes.deviceRow}>
                    <div>
                      <p className={classes.paramterInfo}>
                        <span className={classes.keyName}>Parameter name:</span>
                        {parameter_name}
                      </p>
                      <p className={classes.paramterInfo}>
                        <span className={classes.keyName}>Units: </span>
                        {unit}
                      </p>
                    </div>
                    <div>
                      {/* Added optional tag for issue #301 */}
                      <p className={classes.paramterInfo}>
                        <span className={classes.keyName}>Optional tag: </span>
                        {parameter_tag}
                      </p>
                      <p className={classes.paramterInfo}>
                        <span className={classes.keyName}>Source:</span>
                        {source}
                      </p>
                    </div>
                  </Grid>
                  <hr className={classes.lineBreak} />
                  <Grid item xs={12} className={classes.deviceRow}>
                    {/* calling function to display different UI based on type of local device used as source for issue #167 */}

                  </Grid>
                  {/* condition to not show the min max values for digital outputs devices as sources for issue #167 */}

                </Grid>











              }
              <div className={classes.actionItems}>
                <Button
                  type="button"
                  color="defaultTheme"
                  style={{ margin: "0 1em 0 0" }}
                  onClick={handleCloseViewParametersModal}
                >
                  Close
                </Button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default ViewParameterModal;

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modalOverlay: {
      zIndex: 9000,
      position: "fixed",
      top: 0,
      left: 0,
      minHeight: "100vh",
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "rgba(0,0,0, .7)"
    },
    modalBody: {
      maxHeight: "90vh",
      overflowY: "auto",
      width: 800,
      backgroundColor: Colors.White
    },
    header: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "1rem 2.5rem",
      margin: "0 0 1em 0",
      borderBottom: `1px solid ${Colors.LightGray}`
    },
    inputBody: {
      // maxHeight: 500,
      overflowY: "auto"
    },
    headerTab: {
      width: 200,
      borderBottom: "none",
      cursor: "pointer"
    },
    headerText: {
      fontWeight: 600,
      fontSize: 24,
      padding: 0,
      margin: 0
    },
    unselectedHeader: {
      color: Colors.Gray
    },
    selectedHeader: {
      color: theme.palette.primary.light,
      borderBottom: "none",
      opacity: 1
    },
    icon: {
      color: Colors.LightGray,
      cursor: "pointer",
      fontSize: "3rem"
    },
    pathText: {
      backgroundColor: Colors.LightGray,
      display: "flex",
      padding: ".5rem 2.5rem",
      "& p": {
        padding: 0,
        margin: 0,
        marginRight: "1rem",
        fontSize: "1rem"
      }
    },
    labelText: {
      display: "flex",
      padding: "1rem 2.5rem",
      "& p": {
        padding: 0,
        margin: 0,
        marginRight: "1rem",
        fontSize: "1rem"
      }
    },
    inputsContainer: {
      // maxHeight: "265px",
      // overflowY: "auto",
    },
    label: {
      fontSize: "14px"
    },
    inputGroup: {
      padding: "1rem 2.5rem",
      "& p": {
        padding: 0,
        margin: 0,
        fontSize: "1rem",
        marginBottom: ".5rem"
      },
      "& input": {
        width: "100%",
        padding: ".7rem 1rem",
        border: `1px solid ${Colors.LightGray}`
      },
      "& select": {
        width: "100%",
        padding: "0 1rem",
        border: `1px solid ${Colors.LightGray}`,
        height: 30,
        cursor: "pointer"
      }
    },
    hide: {
      display: "none"
    },
    toggleButtonContainer: {
      display: "flex",
      flexDirection: "row",
      width: 100,
      height: 30
    },
    toggleButton: {
      width: "50%",
      border: `1px solid ${theme.palette.primary.light}`,
      backgroundColor: "#fff",
      color: theme.palette.primary.light,
      fontWeight: 600,
      cursor: "pointer",
      outline: "none"
    },
    selectButton: {
      width: 66,
      border: `1px solid ${theme.palette.primary.light}`,
      backgroundColor: "#fff",
      color: theme.palette.primary.light,
      fontWeight: 600,
      height: 30,
      cursor: "pointer",
      outline: "none"
    },
    active: {
      backgroundColor: `${theme.palette.primary.light}`,
      color: "#fff"
    },
    deleteDescription: {
      padding: "0 2.5rem",
      "& p": {
        padding: 0,
        margin: 0,
        fontSize: "1rem",
        marginBottom: ".5rem"
      }
    },
    addNewPropertyBtnContainer: {
      padding: "1rem 2.5rem"
    },
    addNewPropertyBtn: {
      display: "flex",
      alignItems: "center",
      color: theme.palette.primary.light,
      border: `1px solid ${theme.palette.primary.light}`,
      cursor: "pointer",
      marginRight: "1.5rem",
      fontWeight: "bold",
      backgroundColor: Colors.White,
      outline: "none"
    },
    maxPropertiesWarning: {
      fontSize: "14px",
      fontWeight: 600,
      color: theme.palette.primary.light
    },
    actionItems: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      margin: "1rem 2.5rem",
      borderTop: `1px solid ${Colors.LightGray}`,
      padding: "1rem 0"
    },
    cancelButton: {
      padding: ".7rem 1.5rem",
      color: theme.palette.primary.light,
      border: `1px solid ${theme.palette.primary.light}`,
      cursor: "pointer",
      marginRight: "1.5rem",
      fontWeight: "bold",
      backgroundColor: Colors.White
    },
    saveButton: {
      padding: ".7rem 1.5rem",
      color: Colors.White,
      backgroundColor: theme.palette.primary.light,
      cursor: "pointer",
      fontWeight: "bold",
      border: "none"
    },

    root: {
      padding: "0 2.5rem"
    },
    deviceRow: {
      display: "grid",
      gridTemplateColumns: "1fr 1fr"
    },
    keyName: {
      display: "inline-block",
      width: 180,
      margin: "0 16px 0 0"
    },
    lineBreak: {
      width: "100%",
      border: "none",
      borderBottom: `1px dotted ${Colors.LightGray}`
    },
    paramterInfo: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center"
    },
    circle: {
      display: "inline-block",
      height: 15,
      width: 15,
      borderRadius: "50%"
    },


  })
);
