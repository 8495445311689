export const connectorTypeList = [
  {
    name: "Select",
    value: 0
  },
  {
    name: "Salesforce",
    value: 1
  },
  {
    name: "IHS FieldDIRECT",
    value: 2
  },
];