/* Added this file for  connector UI for github issue #294 */
import React, { useState, useEffect, useCallback } from "react";
import {
  Grid,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Colors } from "common/Colors";
import ellipsis from "assets/bt-assets/more-vertical@2x.svg";
import { SuccessModal } from "components/success-modal/SuccessModal";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "app/rootReducer";
import { setCallConnectors } from "../accountSlice";
import clsx from "clsx";
import AccountAPI from "services/accountsAPI";
import LoadSpinner from "common/LoadSpinner";
import { CreateNewConnectorModal } from "./CreateNewConnectorModal";
import { ConnectorPushModal } from "./ConnectorPushModal";
import { fromUnixTime, format } from "date-fns";
import { customDateFormatUnix } from "utils/helpers";
import UnsuccessModal from "components/success-modal/UnsuccessfullModal"; //imported for issue #213
import ConnectorPopOver from "./ConnectorPopOver";
import UpdateConnectorModal from "./UpdateConnectorModal";
import Button from "components/common-buttons/Button";
import { RequiredConnectorProps } from "features/accounts-page/components/types"
import { connectorTypeList } from './ConnectorTypes';

type ConnectorProps = {
  isBasintrakAdmin: boolean;
  openNewConnectorModal: boolean;
  setOpenNewConnectorModal: Function;
};

const Connector: React.FC<ConnectorProps> = ({
  isBasintrakAdmin,
  openNewConnectorModal,
  setOpenNewConnectorModal
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [allConnectorsList, setAllConnectorsList] = useState<any[]>([]);
  const [connectorActive, setConnectorActive] = useState(false);
  const [callConnector, setCallConnector] = useState(false);
  const [connectorId, setConnectorId] = useState("");
  const [connectorName, setConnectorName] = useState("");
  const [selectedConnector, setSelectedConnector] = useState<RequiredConnectorProps>();
  const [openUpdateConnectorModal, setOpenUpdateConnectorModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [OpenConnectorPushModal, setOpenConnectorPushModal] = useState(false);
  const [currentExpiryDate, setCurrentExpiryDate] = useState<number>(0);
  const [apiSubcriptionLevel, setAPISubscriptionLevel] = useState<number>(0);

  const tenantId = useSelector(
    (state: RootState) => state.AccountSlice.accountInformation?.tenant_id
  );
  const reCallConnectorList = useSelector(
    (state: RootState) => state.AccountSlice.callConnectors
  );
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  // function to open update connector modal when any connector is clicked for issue #294
  const handleUpdateConnector = (connector: RequiredConnectorProps) => {
    setSelectedConnector(connector);
    setConnectorActive(connector?.connector_active);
    setOpenUpdateConnectorModal(true);
  };

  // function to open update connector modal when any connector is clicked for issue #294
  const handleConnectorPush = (connector: RequiredConnectorProps) => {
    setSelectedConnector(connector);
    setConnectorActive(connector?.connector_active);
    setOpenConnectorPushModal(true);
  };

  const handlePushModalClose = () => {
    setOpenConnectorPushModal(false);
  };

  // function to close create or update connector modal for issue #294
  const handleModalClose = () => {
    setOpenNewConnectorModal(false);
    setOpenUpdateConnectorModal(false);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // function to handle three dots click for issue #294
  const ConnectorMenuClickHandler = useCallback(
    (Connector: RequiredConnectorProps, connectorId: string, connectorName: string) => (event: any) => {
      setAnchorEl(event.currentTarget);
      setConnectorActive(Connector?.connector_active);
      setConnectorId(connectorId);
      setConnectorName(connectorName);
    },
    []
  );

  // function to display access to groups or devices or both for issue #294
  const displayAccess = (connectorAccess: any) => {
    return connectorAccess && connectorAccess.length !== 0 ? (
      <p className={classes.role}>
        {connectorAccess.map((accessItem: any) => accessItem).join("; ")}
      </p>
    ) : (
      <p className={classes.role}>No access levels</p>
    );
  };

  // function to display access groups for issue #294
  const displayGroups = (accessGroups: any) => {
    return accessGroups && accessGroups.length !== 0 ? (
      <p className={classes.role}>
        {accessGroups.map((group: any) => group.device_name).join("; ")}
      </p>
    ) : (
      <p className={classes.role}>No assigned group(s)</p>
    );
  };

  useEffect(() => {
    // function to call connector list for issue #294
    const getConnectorList = async () => {
      try {
        // api call to retrieve connector list for issue #294
        const getAllConnectorInfo: any = await AccountAPI.getAllConnectors(tenantId);
        const connectorList = getAllConnectorInfo.data_connector;
        const expiry = Number.parseInt(getAllConnectorInfo?.api_subscription?.expiry ?? 0, 10);
        const level = Number.parseInt(getAllConnectorInfo?.api_subscription?.level ?? 0, 10);
        setCurrentExpiryDate(expiry);
        setAPISubscriptionLevel(level);
        setAllConnectorsList(connectorList);
        setLoading(false);
      } catch (error) {
        alert(error);
        console.log(error);
        setLoading(false);
      }
    };

    //for calling connector list after CRUD operation on connector for issue #294
    if (reCallConnectorList) {
      getConnectorList();
      dispatch(setCallConnectors(false));
    }

    getConnectorList();
  }, [tenantId, reCallConnectorList, dispatch]);

  useEffect(() => {
    // function to call connector list for issue #294
    const getConnectorList = async () => {
      // api call to retrieve connector list for issue #294
      const getAllConnectorInfo = await AccountAPI.getAllConnectors(tenantId);
      const connectorList = getAllConnectorInfo.data_connector;
      const expiry = Number.parseInt(getAllConnectorInfo?.api_subscription?.expiry ?? 0, 10);
      const level = Number.parseInt(getAllConnectorInfo?.api_subscription?.level ?? 0, 10);
      setCurrentExpiryDate(expiry);
      setAPISubscriptionLevel(level);
      setAllConnectorsList(connectorList);
    };

    if (callConnector) {
      getConnectorList();
      setCallConnector(!callConnector);
    }
  }, [tenantId, callConnector]);

  return loading ? (
    <LoadSpinner size="90vh" center={true} />
  ) : (
    <Grid container>
      <Grid item xs={12} className={classes.root}>
        <TableContainer>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell className={classes.columnText}>Connector name</TableCell>
                <TableCell className={classes.columnText}>
                  Connector type
                </TableCell>
                <TableCell className={classes.columnText}>
                  Devices
                </TableCell>
                <TableCell className={classes.columnText}>
                  Last run time
                </TableCell>
                <TableCell className={classes.columnText}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {allConnectorsList?.map((connector: RequiredConnectorProps, index: number) => {
                return (
                  <TableRow
                    key={index}
                    style={{
                      cursor: "pointer",
                      position: "relative"
                    }}
                  >
                    {/* this will have id or some sort of identifier attached to cell to know which connector it is targetting */}
                    <TableCell
                      className={classes.tableCell}
                      onClick={() => {
                        handleUpdateConnector(connector);
                      }}
                    >
                      <div className={classes.companyInformation}>
                        <div
                          className={clsx(classes.dot, {
                            [classes.activeDot]: true
                          })}
                        ></div>
                        <p className={classes.connectorName}>{connector.connector_name}</p>
                      </div>
                    </TableCell>
                    <TableCell
                      className={classes.tableCell}
                      onClick={() => {
                        handleUpdateConnector(connector);
                      }}
                    >
                      {connectorTypeList.find((type) => type.value === connector.connector_type)?.name || 'Unknown'}
                    </TableCell>
                    <TableCell className={classes.tableCell} >
                      {displayGroups(connector.device_list)}
                      {connector.connector_type === 1 && (
                        <p className={classes.createButton}>
                          <Button
                            type="button"
                            color="defaultTheme"
                            onClick={() => {
                              handleConnectorPush(connector);
                            }}
                          >
                            Options
                          </Button>
                          {selectedConnector && (
                            <ConnectorPushModal
                              open={OpenConnectorPushModal}
                              handleClose={handlePushModalClose}
                              selectedConnector={selectedConnector}
                            />
                          )}
                        </p>
                      )}
                    </TableCell>
                    <TableCell
                      className={classes.tableCell}
                      onClick={() => {
                        handleUpdateConnector(connector);
                      }}
                    >
                      {format(fromUnixTime(connector.last_post_time), "MM/dd/yyyy hh:mm:ss a")}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      <div
                        style={{
                          position: "relative",
                          zIndex: 1,
                          display: "flex",
                          justifyContent: "center"
                        }}
                        onClick={ConnectorMenuClickHandler(
                          connector,
                          connector.connector_id,
                          connector.connector_name
                        )}
                      >
                        <img src={ellipsis} alt="hamburger menu" />
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          <ConnectorPopOver
            id={id}
            open={open}
            anchorEl={anchorEl}
            handleClose={handleClose}
            tenantId={tenantId}
            connectorActive={connectorActive}
            connectorId={connectorId}
            connectorName={connectorName}
            setCallConnector={setCallConnector}
          />
          <CreateNewConnectorModal
            open={openNewConnectorModal}
            handleClose={handleModalClose}
            subscription_expiry={currentExpiryDate}
            addNewConnector={apiSubcriptionLevel}
          />
          {selectedConnector && openUpdateConnectorModal && (
            <UpdateConnectorModal
              open={openUpdateConnectorModal}
              handleClose={handleModalClose}
              selectedConnector={selectedConnector}
              setCallConnector={setCallConnector}
            />
          )}
          <SuccessModal />
          {/* imported for issue #213 */}
          <UnsuccessModal />
        </TableContainer>
      </Grid >
    </Grid >
  );
};

export default Connector;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      overflowY: "auto"
    },
    lineBreak: {
      width: "100%",
      border: `1px solid ${Colors.LightGray}`
    },
    buttonContainer: {
      margin: "2rem 0",
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end"
    },
    createButton: {
      // backgroundColor: Colors.White,
      color: theme.palette.primary.light,
      // border: `1px solid ${theme.palette.primary.light}`,
      cursor: "pointer",
      padding: "0 0",
      outline: "none",
      height: 30,
      whiteSpace: "nowrap",
      '&:hover': {
        opacity: 0.8,
      },
    },
    table: {
      minWidth: "100%",
      marginTop: "1rem"
    },
    columnText: {
      color: theme.palette.primary.light,
      fontWeight: 600,
      borderTop: "2px solid #DDD",
      borderBottom: "2px solid #DDD"
    },
    tableCell: {
      padding: "4px 16px",
      margin: 0,
      borderBottom: "2px solid #DDD",
      fontSize: 14
    },
    companyInformation: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      width: "80%",
      cursor: "pointer"
    },
    connectorName: {
      position: "relative",
      right: "9px",
      color: theme.palette.primary.light,
      fontSize: 14
    },
    role: {
      color: Colors.Black,
      fontSize: 14
    },
    accessGroup: {
      color: Colors.Black,
      fontSize: 14
    },
    userEmail: {
      color: theme.palette.primary.light
    },
    userPhone: {
      color: Colors.Black
    },
    comment: {
      height: "1.8rem",
      color: Colors.Gray,
      cursor: "pointer"
    },
    ellipsis: {
      cursor: "pointer",
      color: theme.palette.primary.light,
      height: "2rem",
      zIndex: 100
    },
    dot: {
      backgroundColor: "LightGreen",
      height: ".6rem",
      width: ".6rem",
      borderRadius: "50%",
      position: "relative",
      right: "15px"
    },
    activeDot: {
      backgroundColor: Colors.Green
    },
    inactiveDot: {
      backgroundColor: Colors.LightGray
    },
    notPermitted: {
      opacity: 0.5,
      pointerEvents: "none"
    }
  })
);
