import React, { useState, useEffect, useCallback } from "react";
import CloseIcon from "@material-ui/icons/Close";
import Modal from "@material-ui/core/Modal";
import Xsquare from "assets/bt-assets/x-square.svg";

import { useDispatch, useSelector } from "react-redux";
import { RootState } from "app/rootReducer";
import {
  setSuccessModalOpen,
  setSuccessModalDescription,
  setUnsuccessModalOpen,
  setUnsuccessModalDescription
} from "components/success-modal/successModalSlice";
import { setCallConnectors } from "../accountSlice";
import { Grid } from "@material-ui/core";
import AccountsAPI from "services/accountsAPI";
import { useForm, useWatch, Controller, useFieldArray } from "react-hook-form";
import { InputLabel } from "components/input-label/InputLabel";
import Button from "components/common-buttons/Button";
import Select from "react-select";
import { adminRoles, powerUserRoles } from "./roles";
import { RequiredConnectorProps, UsersGroupsProps, Device, Entity, EntityDevice, EntityParameter } from "./types";
import Checkbox from "@material-ui/core/Checkbox";
import LoadSpinner from "common/LoadSpinner";
import clsx from "clsx";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { Calendar } from "react-date-range";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Colors } from "common/Colors";
import { customDateFormatUnix } from "utils/helpers";
import { fromUnixTime, getUnixTime } from "date-fns";
import calendarWhite from "assets/bt-assets/calendar_white.svg";
import { devicesAPI } from "services/devicesAPI";
import { AxiosError } from "axios";
import { connectorTypeList } from './ConnectorTypes';

type UpdateConnectorModalProps = {
  handleClose: () => void;
  open: boolean;
  selectedConnector: RequiredConnectorProps;
  setCallConnector: Function;
};

export const UpdateConnectorModal: React.FC<UpdateConnectorModalProps> = ({
  handleClose, // to close the create connector modal for issue #294
  open, //to open the create connector modal for issue #294
  selectedConnector,
  setCallConnector
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [calendarOpen, setCalendarOpen] = useState(false);
  const [connectorDate, setConnectorDate] = useState(Number(""));
  const [deviceList, setDeviceList] = useState<Device[]>([]);
  const [entityDeviceList, setEntityDeviceList] = useState<any>([]);

  const [selectedDevices, setSelectedDevices] = useState<Device[]>([]);
  // const [connectorID, setConnectorID] = useState<string>("");
  const [connectorType, setConnectorType] = useState<number>(0);
  const [scheduleDays, setScheduleDays] = useState<number>(0); // Default value is 0 (Hourly)
  const [scheduleDaysLabel, setScheduleDaysLabel] = useState<string>("Hourly");

  const scheduleLabels: { [key: number]: string } = {
    0: "Hourly",
    1: "Daily",
  };

  const { register, trigger, handleSubmit, control, setValue, getValues, reset, formState: { errors } } = useForm<RequiredConnectorProps>({
    defaultValues: selectedConnector || {
      connector_name: "",
      connector_type: 0,
      connector_expiry: "",
      creds: { consumer_key: "", consumer_pk: "", consumer_secret: "", end_point: "", user_name: "", user_pwd: "" },
      device_list: [],
      connector_active: true,
      schedule_hours: "0",
      schedule_tz: "0",
      schedule_days: "0",
      start_date: "",
      entity: [{ entity_name: "", category_name: "", devices: [], parameters: [] }]
    }
  });

  const { userInformation } = useSelector((state: RootState) => state.AppSlice);

  const { tenant_id } = useSelector(
    (state: RootState) => state.AccountSlice.accountInformation
  );

  // Handle dynamic fields for entities
  const { fields: entityFields, append: appendEntity, remove: removeEntity } = useFieldArray({
    control,
    name: "entity",
  });

  const watchedEntities = useWatch({ name: "entity", control }) as Entity[];

  // useEffect(() => {
  //   console.log("entity updated:", watchedEntities);
  // }, [watchedEntities]);

  const { access_level } = useSelector(
    (state: RootState) => state.AppSlice.userInformation
  );

  useEffect(() => {
    if (selectedConnector?.entity) {
      setValue("entity", selectedConnector.entity, { shouldValidate: true });
    }
  }, [selectedConnector, setValue]);

  useEffect(() => {
    const getDeviceList = async () => {
      try {
        const updatedDeviceList: Device[] = [...deviceList];
        const updatedEntityDeviceList: Device[] = [...entityDeviceList];
        const deviceNameList = await devicesAPI.retrieveListOfDevices(tenant_id);

        deviceNameList.forEach((devicesObj: { device_name: string; device_id: string }) => {
          const temp: Device = {
            device_name: devicesObj.device_name,
            device_id: devicesObj.device_id,
            last_push_status: false,
            last_push_time: 0,
            last_response: 0,
          };
          updatedDeviceList.push(temp); // Matches the Device structure
          updatedEntityDeviceList.push(temp); // Same structure
        });
        setDeviceList(updatedDeviceList);
        setEntityDeviceList(updatedEntityDeviceList);
      } catch (error) {
        alert('Error fetching device list');
        console.error(error);
      }
    };

    getDeviceList();
  }, [tenant_id]);

  // to set the calendar date timestamp for issue #294
  const calendarExpiryHandler = useCallback(
    (date: Date) => {
      const expiry = getUnixTime(date);
      setConnectorDate(expiry);
    },
    [connectorDate]
  );

  useEffect(() => {
    if (selectedConnector) {
      if (Array.isArray(selectedConnector.device_list) && selectedConnector.device_list.length > 0) {
        const devices = selectedConnector.device_list.map((device: Device) => ({
          ...device,
        }));
        setSelectedDevices(devices);
      }
    }
  }, [selectedConnector]);

  // Handle creating connector
  const handleCreateConnector = async (
    formData: RequiredConnectorProps) => {
    try {
      const {
        connector_name,
        connector_type,
        creds: { consumer_key, consumer_pk, consumer_secret, end_point, user_name, user_pwd },
        schedule_days,
        schedule_hours,
        schedule_tz,
        start_date,
      } = formData;

      const selectedDeviceList = selectedDevices.map(
        (device: any) => ({
          device_name: device.device_name,
          device_id: device.device_id,
        })
      );

      setLoading(true);

      const sanitizedEntities = watchedEntities.map((entity) => {
        const { id, ...rest } = entity as Entity & { id?: string };
        return rest;
      });

      if (connector_type === 2) {
        setValue("creds.end_point", "endpoint");
      }
      const updatedEndPoint = getValues("creds.end_point") as string;

      // API call to create connector
      const response = await AccountsAPI.updateConnector(
        tenant_id,
        connector_name,
        selectedConnector.connector_id,
        connector_type,
        selectedDeviceList,
        consumer_key,
        consumer_pk,
        consumer_secret,
        updatedEndPoint,
        user_name,
        user_pwd,
        schedule_hours,
        schedule_days,
        schedule_tz,
        start_date,
        sanitizedEntities
      );

      setLoading(false);
      dispatch(setCallConnectors(true));
      dispatch(setSuccessModalDescription("Connector successfully added!"));
      dispatch(setSuccessModalOpen(true));
      setConnectorDate(Number("")); //resetting the connector date
      handleModalClose();
    } catch (error) {

      if ((error as AxiosError).isAxiosError) {
        // AxiosError: Handle known Axios errors
        const { response, request, message } = error as AxiosError;

        if (response) {
          // Server returned a response with an error status
          const errorMessage = response.data?.Message
            ? `Error ${response.status}: ${response.data.Message}`
            : `HTTP Error ${response.status}`;

          dispatch(setUnsuccessModalDescription(errorMessage));
          dispatch(setUnsuccessModalOpen(true));
        } else if (request) {
          // No response received from the server
          alert("Network Error: No response received from the server.");
        }
      } else if (error instanceof Error) {
        // General JS Error
        alert(`Error: ${error.message}`);
      } else {
        // Unknown error type
        alert("An unknown error occurred.");
      }
    }
    setLoading(false);
  }

  const handleAddDevice = (entityIndex: number) => {
    // Use watchedEntities to access the current form state
    const currentEntities = watchedEntities || [];
    const updatedEntities = [...currentEntities];

    // Ensure the selected entity exists in the list
    if (!updatedEntities[entityIndex]) {
      updatedEntities[entityIndex] = { devices: [], parameters: [], entity_name: "", category_name: "" };
    }

    // Add a new device to the specific entity's devices list
    const newDevice = {
      device_name: "",
      device_id: "",
      entity_id: "",
      alt_name: "",
      last_response: 0,
      last_message: "",
      last_push_time: 0
    };
    updatedEntities[entityIndex].devices.push(newDevice);

    // Update the form state using setValue
    setValue("entity", updatedEntities, { shouldValidate: true });
  };

  const handleRemoveDevice = (entityIndex: number, deviceIndex: number) => {
    // Create a deep copy of the watchedEntities array to ensure immutability
    const updatedEntities = watchedEntities.map((entity, idx) =>
      idx === entityIndex
        ? {
          ...entity,
          devices: entity.devices.filter((_, dIdx) => dIdx !== deviceIndex),
        }
        : entity
    );

    setValue("entity", updatedEntities, { shouldValidate: true });
    trigger();
  };


  // Function to handle adding/removing a parameter from an entity
  const handleAddParameter = (entityIndex: number) => {
    const newParameter = { parameter_name: "" };
    const updatedEntities = [...entityFields];
    updatedEntities[entityIndex].parameters.push(newParameter); // Add a new parameter
    setValue("entity", updatedEntities); // Update the form state
  };

  const handleRemoveParameter = (entityIndex: number, parameterIndex: number) => {
    const updatedEntities = [...entityFields];
    updatedEntities[entityIndex].parameters.splice(parameterIndex, 1); // Remove parameter at the given index
    setValue("entity", updatedEntities); // Update the form state
  };

  const handleConnectorTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = Number(event.target.value);
    setConnectorType(value);
    setValue("connector_type", value);
  };
  const handleDeviceChange = (selectedOptions: any) => {

    const updatedDevices = selectedOptions.map((option: any) => ({
      device_id: option.value,
      device_name: option.label,
      last_push_status: option?.last_push_status ?? null,
      last_push_time: option?.last_push_time ?? null,
      last_response: option?.last_response ?? null,
    }));
    setSelectedDevices(updatedDevices);
  };

  const handleModalClose = () => {
    setValue('entity', []);
    setConnectorType(0);
    setScheduleDays(0);
    handleClose();
  };

  const handleCategoryNameChange = (e: React.ChangeEvent<HTMLInputElement>, entityIndex: number) => {
    const updatedEntities = [...(watchedEntities)];
    if (!updatedEntities[entityIndex]) {
      updatedEntities[entityIndex] = {
        devices: [{
          device_name: "",
          device_id: "",
          entity_id: "",
          alt_name: "",
          last_response: 0,
          last_message: "",
          last_push_time: 0
        }],
        parameters: [{
          parameter_name: "",
          avg: "",
          latest: "",
          min: "",
          max: "",
          diff_24h: "",
          conversion: ""
        }],
        entity_name: "",
        category_name: "",
      };
    }
    updatedEntities[entityIndex].category_name = e.target.value;
    setValue("entity", updatedEntities, { shouldValidate: true });
  };

  const renderFieldsForConnectorType = () => {
    switch (selectedConnector.connector_type) {
      case 1: // Fields for Salesforce
        return (
          <>
            <Grid item>
              <div className={classes.inputGroup}>
                <InputLabel
                  label="Select devices"
                  name="device_events"
                  errors={errors}
                />
                <Select
                  name="connector_device_events"
                  value={selectedDevices.map(device => ({
                    value: device.device_id,
                    label: device.device_name
                  }))}
                  onChange={handleDeviceChange}
                  options={deviceList.map(device => ({
                    value: device.device_id,
                    label: device.device_name
                  }))}
                  maxMenuHeight={220}
                  classNamePrefix="select"
                  placeholder="Select devices"
                  isMulti
                  isSearchable
                />
              </div>
            </Grid>
            <Grid item>
              <div className={classes.inputGroup}>
                {/* input for connector name for issue #294 */}
                <InputLabel
                  label="Consumer key"
                  name="creds.consumer_key"
                  errors={errors}
                />
                <input
                  name="creds.consumer_key"
                  ref={register({
                    required: "This field is required."
                  })}
                  onChange={(e) => setValue("creds.consumer_key", e.target.value)}
                  maxLength={35}
                  type="text"
                  placeholder="Enter"
                />
              </div>
            </Grid>
            <Grid item>
              <div className={classes.inputGroup}>
                {/* input for connector name for issue #294 */}
                <InputLabel
                  label="Consumer private key"
                  name="creds.consumer_pk"
                  errors={errors}
                />
                <input
                  name="creds.consumer_pk"
                  ref={register({
                    required: "This field is required."
                  })}
                  onChange={(e) => setValue("creds.consumer_pk", e.target.value)}
                  maxLength={1000}
                  type="text"
                  placeholder="Enter"
                  className={classes.resizableInput}
                />
              </div>
            </Grid>
            <Grid item>
              <div className={classes.inputGroup}>
                {/* input for connector name for issue #294 */}
                <InputLabel
                  label="Consumer secret"
                  name="creds.consumer_secret"
                  errors={errors}
                />
                <input
                  name="creds.consumer_secret"
                  ref={register({
                    required: "This field is required."
                  })}
                  onChange={(e) => setValue("creds.consumer_secret", e.target.value)}
                  maxLength={35}
                  type="text"
                  placeholder="Enter"
                />
              </div>
            </Grid>
            <Grid item>
              <div className={classes.inputGroup}>
                {/* input for connector name for issue #294 */}
                <InputLabel
                  label="End point"
                  name="creds.end_point"
                  errors={errors}
                />
                <input
                  name="creds.end_point"
                  ref={register({
                    required: "This field is required."
                  })}
                  onChange={(e) => setValue("creds.end_point", e.target.value)}
                  maxLength={35}
                  type="text"
                  defaultValue=""
                  placeholder="Enter"
                />
              </div>
            </Grid>
            <Grid item>
              <div className={classes.inputGroup}>
                {/* input for connector name for issue #294 */}
                <InputLabel
                  label="User name"
                  name="creds.user_name"
                  errors={errors}
                />
                <input
                  name="creds.user_name"
                  ref={register({
                    required: "This field is required."
                  })}
                  onChange={(e) => setValue("creds.user_name", e.target.value)}
                  maxLength={35}
                  type="text"
                  placeholder="Enter"
                />
              </div>
            </Grid>
            <Grid item>
              <div className={classes.inputGroup}>
                {/* input for connector name for issue #294 */}
                <InputLabel
                  label="User password"
                  name="creds.user_pwd"
                  errors={errors}
                />
                <input
                  name="creds.user_pwd"
                  ref={register({
                    required: "This field is required."
                  })}
                  onChange={(e) => setValue("creds.user_pwd", e.target.value)}
                  maxLength={35}
                  type="text"
                  placeholder="Enter"
                />
              </div>
            </Grid>
          </>
        );
      case 2: // Fields for IHS FieldDIRECT
        return (
          <>
            <Grid item>
              <div className={classes.inputGroup}>
                {/* Dropdown for Push Interval */}
                <InputLabel
                  label="Push interval"
                  name="schedule_days"
                  errors={errors}
                />
                <select
                  name="schedule_days"
                  ref={register}
                  // value={schedule_days}
                  onChange={(e) => {
                    const value = Number(e.target.value);
                    setScheduleDays(value);
                    setValue("schedule_days", value);
                    setScheduleDaysLabel(scheduleLabels[value] || ""); // Update label dynamically
                  }}
                >
                  <option value={0}>Interval based</option>
                  <option value={1}>Daily</option>
                </select>
              </div>
            </Grid>
            <Grid item>
              <div className={classes.inputGroup} style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
                {/* Input for "Schedule hours" */}
                <div style={{ flex: 1 }}>
                  <InputLabel
                    label={`${scheduleDaysLabel}`}
                    name="schedule_hours"
                    errors={errors}
                  />
                  <select
                    name="schedule_hours"
                    ref={register({
                      required: "This field is required."
                    })}
                    // defaultValue=""
                    style={{
                      width: "100%",
                      padding: "0.5rem",
                      border: "1px solid #ccc",
                      borderRadius: "0px",
                    }}
                  >
                    <option value="" disabled>
                      Select
                    </option>
                    {Array.from({ length: 24 }, (_, groupIndex) => {
                      const formattedHour = groupIndex.toString().padStart(2, "0") + ":00";
                      return (
                        <option key={groupIndex} value={formattedHour}>
                          {formattedHour}
                        </option>
                      );
                    })}
                  </select>
                </div>

                {scheduleDays === 1 && (
                  <div style={{ flex: 2 }}>
                    <InputLabel
                      label="Select timezone"
                      name="schedule_tz"
                      errors={errors}
                    />
                    <select
                      name="schedule_tz"
                      ref={register({
                        required: "This field is required."
                      })}
                      defaultValue={0}
                      style={{
                        width: "100%",
                        padding: "0.5rem",
                        border: "1px solid #ccc",
                        borderRadius: "4px",
                      }}
                    >
                      <option value={0} disabled>Select</option>
                      <option value={0}>US/Eastern Standard Time</option>
                      <option value={1}>US/Central Standard Time</option>
                      <option value={2}>US/Mountain Standard Time</option>
                      <option value={3}>US/Pacific Standard Time</option>
                      <option value={4}>US/Alaska Standard Time</option>
                      <option value={5}>US/Hawaii Standard Time</option>
                    </select>
                  </div>
                )}
              </div>
            </Grid>
            <Grid item>
              <div className={classes.inputGroup}>
                {/* input for connector name for issue #294 */}
                <InputLabel
                  label="AppKey"
                  name="creds.consumer_key"
                  errors={errors}
                />
                <input
                  name="creds.consumer_key"
                  ref={register({
                    required: "This field is required."
                  })}
                  maxLength={100}
                  type="text"
                  placeholder="Enter"
                />
              </div>
            </Grid>
            <Grid item>
              <div className={classes.inputGroup}>
                {/* input for connector name for issue #294 */}
                <InputLabel
                  label="User name"
                  name="creds.user_name"
                  errors={errors}
                />
                <input
                  name="creds.user_name"
                  ref={register({
                    required: "This field is required."
                  })}
                  maxLength={35}
                  type="text"
                  placeholder="Enter"
                />
              </div>
            </Grid>
            <Grid item>
              <div className={classes.inputGroup}>
                {/* input for connector name for issue #294 */}
                <InputLabel
                  label="User password"
                  name="creds.user_pwd"
                  errors={errors}
                />
                <input
                  name="creds.user_pwd"
                  ref={register({
                    required: "This field is required."
                  })}
                  maxLength={35}
                  type="text"
                  placeholder="Enter"
                />
              </div>
            </Grid>
            <Grid item>
              <div className={classes.inputGroup}>
                <>
                  <Grid item>
                    {/* Entities */}
                    {watchedEntities.map((entity, entityIndex) => (
                      <div key={entityIndex}
                        style={{ border: "1px solid #ccc", marginBottom: "0.5rem", padding: "1rem" }}
                      >
                        {/* Line for Entity Details and Remove Button */}
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            marginBottom: "0.5rem",
                          }}
                        >
                          {/* Entity Details */}
                          <h3>Entity {entityIndex + 1}</h3>
                          {/* Remove Entity */}
                          {watchedEntities.length > 1 && (
                            <img
                              src={Xsquare}
                              className={classes.faIcon}
                              style={{
                                color: "red",
                                cursor: "pointer",
                                background: "none",
                                border: "none",
                                fontSize: "1rem",
                              }}
                              onClick={() => { removeEntity(entityIndex); }}
                            />
                          )}
                        </div>
                        <div>
                          <InputLabel
                            label="Entity"
                            name={`watchedEntities.${entityIndex}.entity_name`}
                            errors={errors}
                          />
                          <select
                            name={`watchedEntities.${entityIndex}.entity_name`}
                            value={watchedEntities?.[entityIndex]?.entity_name || ""}
                            onChange={(e) => {
                              const updatedEntities = [...(watchedEntities || [])];
                              if (!updatedEntities[entityIndex]) {
                                updatedEntities[entityIndex] = { devices: [], parameters: [], entity_name: "", category_name: "" };
                              }
                              updatedEntities[entityIndex].entity_name = e.target.value;
                              const defaultNames =
                                e.target.value === "DocGasMeterReadings"
                                  ? ["Differential", "Static", "Temperature", "LinePressure", "Volume"]
                                  : ["ProductID", "Inches", "Temperature", "LinePressure"];
                              updatedEntities[entityIndex].parameters = defaultNames.map((defaultName) => ({
                                parameter_name: "", latest: defaultName, min: "", max: "", avg: "", diff_24h: "", conversion: ""
                              }));
                              setValue("entity", updatedEntities, { shouldValidate: true });
                            }}
                            ref={register({
                              required: "This field is required."
                            })}
                            style={{ display: "block", marginBottom: "0.5rem", padding: "0.5rem", width: "100%" }}
                            defaultValue={watchedEntities?.[entityIndex]?.entity_name || ""}
                          >
                            <option value="" disabled={!!watchedEntities?.[entityIndex]?.entity_name}>
                              Select
                            </option>
                            <option value="DocGasMeterReadings">DocGasMeterReadings</option>
                            <option value="DocTankGauges">DocTankGauges</option>
                          </select>
                          <div>
                            <InputLabel
                              label="Category"
                              name={`watchedEntities.${entityIndex}.category_name`}
                              errors={errors}
                            />
                            <input
                              name={`watchedEntities.${entityIndex}.category_name`}
                              value={watchedEntities?.[entityIndex]?.category_name || ""}
                              onChange={(e) => handleCategoryNameChange(e, entityIndex)}
                              placeholder="Enter name"
                              style={{ display: "block", marginBottom: "0.5rem" }}
                              ref={register({
                                required: "Category name is required"
                              })}
                              defaultValue={watchedEntities?.[entityIndex]?.category_name || ""}
                            />
                          </div>
                        </div>
                        {/* Devices */}
                        <div>
                          <h4>Devices</h4>
                          <div style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "1rem",
                            marginBottom: "0.5rem",
                            borderRadius: "0"
                          }}>
                            {(watchedEntities[entityIndex].devices || []).map((device: any, deviceIndex: number) => (
                              < div key={deviceIndex}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "1rem",
                                  borderRadius: "0"
                                }}
                              >
                                <div style={{ flex: 2 }}>
                                  <InputLabel
                                    label={`Device ${deviceIndex + 1}`}
                                    name={`watchedEntities[${entityIndex}].devices[${deviceIndex}].device_name`}
                                    errors={errors}
                                    style={{ marginBottom: "0.5rem", marginTop: "0.5rem" }}
                                  />
                                  <Controller
                                    name={`watchedEntities[${entityIndex}].devices[${deviceIndex}].device_name`}
                                    control={control}
                                    defaultValue={watchedEntities?.[entityIndex]?.devices?.[deviceIndex]?.device_name || ""}
                                    rules={{
                                      required: "This field is required.",
                                    }}
                                    render={({ field }: any) => (
                                      <Select
                                        {...field}
                                        options={entityDeviceList.map((device: Device) => ({
                                          label: device.device_name,
                                          value: device.device_id,
                                        }))}
                                        value={
                                          entityDeviceList.find((device: any) => device.device_name === watchedEntities[entityIndex]?.devices?.[deviceIndex]?.device_name)
                                            ? {
                                              label: watchedEntities[entityIndex]?.devices?.[deviceIndex]?.device_name,
                                              value: entityDeviceList.find(
                                                (device: any) => device.device_name === watchedEntities[entityIndex]?.devices?.[deviceIndex]?.device_name
                                              )?.device_id,
                                            }
                                            : null
                                        }
                                        placeholder="Select"
                                        isSearchable
                                        maxMenuHeight={220}
                                        onChange={(selectedOptions: { label: string, value: string }) => {
                                          const selectedDeviceNames = selectedOptions?.label || "";
                                          const selectedDeviceIds = selectedOptions?.value || "";
                                          const updatedEntities = [...(watchedEntities || [])];
                                          if (!updatedEntities?.[entityIndex]) {
                                            updatedEntities[entityIndex] = { entity_name: "", category_name: "", devices: [], parameters: [] };
                                          }
                                          if (!updatedEntities[entityIndex].devices[deviceIndex]) {
                                            updatedEntities[entityIndex].devices[deviceIndex] = {
                                              device_name: "",
                                              device_id: "",
                                              entity_id: "",
                                              alt_name: "",
                                              last_message: "",
                                              last_push_time: 0,
                                              last_response: 0,
                                            };
                                          }
                                          updatedEntities[entityIndex].devices[deviceIndex].device_name = selectedDeviceNames;
                                          updatedEntities[entityIndex].devices[deviceIndex].device_id = selectedDeviceIds;
                                          setValue("entity", updatedEntities, { shouldValidate: true });
                                          setValue("watchedEntities", updatedEntities, { shouldValidate: true });
                                        }}
                                        styles={{
                                          control: (base: any) => ({
                                            ...base,
                                            borderRadius: 0,
                                          }),
                                          menu: (base: any) => ({
                                            ...base,
                                            borderRadius: 0,
                                          }),
                                        }}

                                      />
                                    )}
                                  />
                                </div>
                                <div style={{ flex: 1 }}>
                                  <InputLabel
                                    label="Entity ID"
                                    name={`watchedEntities.${entityIndex}.devices.${deviceIndex}.entity_id`}
                                    errors={errors}
                                    style={{ marginBottom: "0.5rem", marginTop: "0.5rem" }}
                                  />
                                  <input
                                    name={`watchedEntities.${entityIndex}.devices.${deviceIndex}.entity_id`}
                                    value={watchedEntities?.[entityIndex]?.devices?.[deviceIndex]?.entity_id || ""}
                                    onChange={(e) => {
                                      const updatedEntities = [...(watchedEntities || [])];
                                      updatedEntities[entityIndex].devices[deviceIndex].entity_id = e.target.value;
                                      setValue("entity", updatedEntities, { shouldValidate: true });
                                    }}
                                    defaultValue={watchedEntities?.[entityIndex]?.devices?.[deviceIndex]?.entity_id || ""}
                                    ref={register({
                                      required: "This field is required."
                                    })}
                                    placeholder="Enter ID"
                                  />
                                </div>
                                {/* Remove Device */}
                                {
                                  entityFields[entityIndex]?.devices.length > 1 && (
                                    <img
                                      src={Xsquare}
                                      alt="Remove Device"
                                      className={classes.faIcon}
                                      style={{ cursor: "pointer", flexShrink: 0 }} // Ensure it doesn't resize
                                      onClick={() => handleRemoveDevice(entityIndex, deviceIndex)}
                                    />
                                  )
                                }
                              </div>
                            ))}
                          </div>
                          <button
                            type="button"
                            onClick={() => handleAddDevice(entityIndex)}
                            style={{ marginTop: "0.5rem" }}
                          >
                            Add Device
                          </button>
                        </div>
                        {/* Parameters */}
                        {
                          ["DocGasMeterReadings", "DocTankGauges"].includes(
                            watchedEntities[entityIndex]?.entity_name
                          ) && (
                            <div style={{ marginTop: "1rem" }}>
                              <h4>Parameters</h4>
                              {(
                                watchedEntities[entityIndex]?.entity_name === "DocGasMeterReadings"
                                  ? ["Differential", "Static", "Temperature", "LinePressure", "Volume"]
                                  : ["ProductID", "Inches", "Temperature", "LinePressure"]
                              ).map((defaultName, paramIndex) => (
                                <div
                                  key={paramIndex}
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginBottom: "0.5rem",
                                    gap: "1rem", // Add gap for spacing
                                  }}
                                >
                                  {/* Label for Parameter */}
                                  <label
                                    htmlFor={`parameter-${paramIndex}`}
                                    style={{
                                      width: "130px", // Fixed width for label alignment
                                    }}
                                  >
                                    {defaultName}
                                  </label>

                                  {/* Parameter Name Input */}
                                  <input
                                    name={`watchedEntities.${entityIndex}.parameters.${paramIndex}.parameter_name`}
                                    defaultValue={watchedEntities?.[entityIndex]?.parameters?.[paramIndex]?.parameter_name || ""}
                                    value={watchedEntities?.[entityIndex]?.parameters?.[paramIndex]?.parameter_name || ""}
                                    onChange={(e) => {
                                      const updatedEntities = [...(watchedEntities || [])];
                                      if (!updatedEntities[entityIndex]?.parameters) {
                                        updatedEntities[entityIndex] = {
                                          ...updatedEntities[entityIndex],
                                          parameters: [],
                                        };
                                      }
                                      const parameter = updatedEntities[entityIndex].parameters[paramIndex] || {
                                        parameter_name: "", latest: "", min: "", max: "", avg: "", diff_24h: "", conversion: "",
                                      };
                                      parameter.parameter_name = e.target.value;
                                      parameter.latest = defaultName;
                                      const parameterValues: { [key: string]: { avg: string, min: string, max: string } } = {
                                        Temperature: { avg: "AvgTemp", min: "MinTemp", max: "MaxTemp" },
                                        LinePressure: { avg: "AvgLinePress", min: "MinLinePress", max: "MaxLinePress" },
                                      };
                                      if (
                                        watchedEntities[entityIndex]?.entity_name === "DocGasMeterReadings" &&
                                        parameterValues[defaultName]
                                      ) {
                                        parameter.avg = parameterValues[defaultName].avg;
                                        parameter.min = parameterValues[defaultName].min;
                                        parameter.max = parameterValues[defaultName].max;
                                      }
                                      updatedEntities[entityIndex].parameters[paramIndex] = parameter;
                                      setValue("entity", updatedEntities, { shouldValidate: true });
                                    }}
                                    ref={register({
                                      required: "This field is required."
                                    })}
                                    placeholder={`Enter parameter name for ${defaultName}`}
                                    style={{
                                      flex: 1,
                                      padding: "0.5rem",
                                      border: "1px solid #ccc",
                                    }}
                                  />
                                  {/* Conversion Dropdown */}
                                  <select
                                    onChange={(e) => {
                                      const updatedEntities = [...(watchedEntities || [])];
                                      // Ensure parameters array exists
                                      if (!updatedEntities[entityIndex]?.parameters) {
                                        updatedEntities[entityIndex] = {
                                          ...updatedEntities[entityIndex],
                                          parameters: [],
                                        };
                                      }
                                      // Ensure specific parameter object exists
                                      if (!updatedEntities[entityIndex].parameters[paramIndex]) {
                                        updatedEntities[entityIndex].parameters[paramIndex] = {
                                          parameter_name: "", latest: "", min: "", max: "", avg: "", diff_24h: "", conversion: ""
                                        };
                                      }
                                      updatedEntities[entityIndex].parameters[paramIndex].conversion = e.target.value;
                                      setValue("entity", updatedEntities, { shouldValidate: true });
                                    }}
                                    style={{
                                      flex: 1, // Make select input take up remaining space
                                      padding: "0.5rem",
                                      border: "1px solid #ccc",
                                      maxWidth: "100px",
                                      width: "80px",
                                    }}
                                    defaultValue={watchedEntities?.[entityIndex]?.parameters?.[paramIndex]?.conversion || ""}
                                  >
                                    {/* Conversion options */}
                                    <option value="0">None</option>
                                    <option value="1">in to ft</option>
                                    <option value="2">ft to in</option>
                                  </select>
                                </div>
                              ))}
                            </div>
                          )
                        }
                      </div>
                    ))}
                    {/* Add Entity */}
                    <button
                      type="button"
                      onClick={() =>
                        appendEntity({
                          entity_name: "",
                          category_name: "",
                          devices: [{ device_name: "" }],
                          parameters: [{ parameter_name: "" }],
                        })
                      }
                      style={{ display: "block", marginTop: "1rem" }}
                    >
                      Add Entity
                    </button>
                  </Grid >
                </>
              </div>
            </Grid >
          </>
        );
      default:
        return null; // Hide all fields
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleModalClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div className={classes.modalOverlay}>
        {loading ? (
          <LoadSpinner />
        ) : (
          <form onSubmit={handleSubmit(handleCreateConnector)}>
            <Grid className={classes.modalBody}>
              <Grid item xs={12} className={classes.header}>
                <p className={classes.headerText}>Edit Connector</p>
                <CloseIcon
                  className={classes.closeIcon}
                  onClick={handleModalClose}
                />
              </Grid>
              <div className={classes.innerInputs}>
                <Grid item>
                  <div className={classes.inputGroup}>
                    {/* Input for connector name */}
                    <InputLabel
                      label="Connector name"
                      name="connector_name"
                      errors={errors}
                    />
                    <input
                      name="connector_name"
                      ref={register({ required: "This field is required." })}
                      maxLength={35}
                      type="text"
                      placeholder="Enter"
                      defaultValue={selectedConnector.connector_name}
                    />
                  </div>
                </Grid>
                <Grid item>
                  <div className={classes.inputGroup}>
                    <InputLabel label="Connector Type" name="connector_type" errors={errors} />
                    <div className={classes.connectorTypeDisplay}>
                      {connectorTypeList.find(connector => connector.value === selectedConnector?.connector_type)?.name || "Unknown"}
                    </div>
                  </div>
                </Grid>

                <Grid item>
                  {renderFieldsForConnectorType()}
                </Grid>
                <Grid
                  item
                  xs={12}
                  className={classes.actionItems}
                  style={{ justifyContent: "flex-end" }}
                >
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <Button
                      type="button"
                      color="white"
                      onClick={handleModalClose}
                    >
                      Cancel
                    </Button>
                    <Button type="submit" color="defaultTheme">
                      Save
                    </Button>
                  </div>
                </Grid>
              </div>
            </Grid>
          </form>
        )}
      </div>
    </Modal>
  );
};


export default UpdateConnectorModal;

const inputStyle = {
  "& p": {
    padding: 0,
    margin: 0,
    fontSize: "1rem",
    marginBottom: ".5rem"
  },
  "& input": {
    width: "100%",
    padding: ".5rem 1rem",
    border: `1px solid ${Colors.LightGray}`
  },
  "& select": {
    width: "100%",
    padding: ".5rem 1rem",
    border: `1px solid ${Colors.LightGray}`,
    cursor: "pointer"
  }
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modalOverlay: {
      zIndex: 9000,
      position: "fixed",
      top: 0,
      left: 0,
      minHeight: "100vh",
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "rgba(0,0,0, .7)"
    },
    connectorTypeDisplay: {
      padding: "0.5rem 1rem",
      border: `1px solid ${Colors.LightGray}`,
      backgroundColor: "#f5f5f5",
      borderRadius: "4px",
      fontSize: "1rem",
      color: "#333",
    },
    modalBody: {
      maxHeight: "90vh",
      overflowY: "auto",
      width: 700,
      // overflowY: "auto",
      backgroundColor: Colors.White,
      zIndex: 1
    },
    resizableInput: {
      width: "100%", // Adjust width as needed
      padding: "10px",
      fontSize: "16px",
      // borderRadius: "4px",
      border: "1px solid #ccc",
      resize: "both", // Allows resizing both horizontally and vertically
      overflowY: "auto", // Ensures content is handled correctly during resizing
    },
    header: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "1rem 2.5rem",
      margin: "0 0 1em 0",
      borderBottom: `1px solid ${Colors.LightGray}`
    },
    headerText: {
      color: theme.palette.primary.light,
      fontWeight: 600,
      fontSize: 24,
      padding: 0,
      margin: 0
    },
    closeIcon: {
      color: Colors.LightGray,
      cursor: "pointer",
      fontSize: "3rem"
    },
    innerInputs: {
      padding: "0 2.5rem",
      width: "100%"
    },
    heartIcon: {
      color: theme.palette.primary.light,
      cursor: "pointer"
    },
    inputGroup: {
      padding: "1rem 1rem 1rem 0",
      ...inputStyle
    },
    faIcon: {
      color: theme.palette.primary.light,
    },
    inputGroupRight: {
      padding: "1rem 0 1rem 1rem",
      ...inputStyle
    },
    labelContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between"
    },
    buttonContainer: {
      height: 30
    },
    button: {
      color: theme.palette.primary.light,
      border: `1px solid ${theme.palette.primary.light}`,
      backgroundColor: Colors.White,
      cursor: "pointer",
      padding: "0 1rem",
      outline: "none",
      height: 30
    },
    disabledButton: {
      color: theme.palette.primary.light,
      border: `1px solid ${theme.palette.primary.light}`,
      backgroundColor: Colors.LightGray,
      cursor: "none",
      padding: "0 1rem",
      outline: "none",
      height: 30
    },
    isActive: {
      backgroundColor: theme.palette.primary.light,
      color: Colors.White
    },
    active: {
      color: Colors.Green,
      fontWeight: 600,
      margin: "0 0 0 1rem"
    },
    notActive: {
      color: Colors.LightGray,
      fontWeight: 600,
      margin: "0 0 0 1rem"
    },
    accountInformation: {
      borderTop: `1px solid ${Colors.LightGray}`
    },
    accessAccount: {
      display: "flex",
      flexDirection: "row",
      alignItems: "baseline",
      "& p": {
        fontSize: 16,
        color: theme.palette.primary.light,
        margin: "0 0 0 .5rem",
        fontWeight: 600
      }
    },
    actionItems: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      margin: "1rem 2.5rem 0",
      borderTop: `1px solid ${Colors.LightGray}`,
      padding: "1rem 0"
    },
    inputName: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between"
    },
    group: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between"
    },
    groupBadge: {
      backgroundColor: "#EBECF0",
      // borderRadius: "2em",
      color: "#172B4D",
      display: "inline-block",
      fontSize: 12,
      fontWeight: "normal",
      lineHeight: "1",
      minWidth: 1,
      padding: "0.16666666666667em 0.5em",
      textAlign: "center"
    },
    isBasinTrakEmployee: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between"
    },
    invalidText: {
      margin: "0 0 0 1px",
      color: "#ED254E"
    },
    calendarDiv: {
      position: "absolute",
      zIndex: 999,
      top: "40%",
      left: "60%",

      [`@media (min-height:800px)`]: {
        top: "auto",
        left: "auto",
        marginTop: "30px"
      }
    }
  })
);

const reactSelectStyles = {
  control: (provided: any) => ({
    ...provided,
    // borderRadius: 0,
    border: `1px solid ${Colors.LightGray}`,
    cursor: "pointer",
    width: "375px",
  }),
};
