import React, { useState, useEffect, useRef } from "react";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import { getUnixTime, fromUnixTime, format, addDays } from "date-fns";
import CalendarTodayOutlinedIcon from "@material-ui/icons/CalendarTodayOutlined";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { Button } from "@material-ui/core";
import clsx from "clsx";
import { Colors } from "common/Colors";

export type ExpirySelectorProps = {
  setExpiryDate: (date: number) => void; // Unix timestamp
  currentExpiry: number; // Unix timestamp
  style?: Object;
};

type FocusedRange = [number, number];

const useAlert = (
  ref: any,
  setToggleCalendar: Function,
  setIsDateRangeVisible: Function,
  buttonState: string
) => {
  useEffect(() => {
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        setToggleCalendar(false);
        setIsDateRangeVisible(true);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, setToggleCalendar, setIsDateRangeVisible, buttonState]);
};

export const ExpirySelector: React.FC<ExpirySelectorProps> = ({
  setExpiryDate,
  currentExpiry,
  style
}) => {
  const classes = useStyles();
  const [startDate] = useState(new Date());
  const [endDate, setEndDate] = useState<Date | null>(fromUnixTime(currentExpiry));
  const [tempEndDate, setTempEndDate] = useState<Date | null>(fromUnixTime(currentExpiry));
  const [toggleCalendar, setToggleCalendar] = useState(false);
  const [isDateRangeVisible, setIsDateRangeVisible] = useState(true);
  const buttonContainer = useRef(null);
  const [focusedRange, setFocusedRange] = useState<FocusedRange>([0, 1]);

  useAlert(buttonContainer, setToggleCalendar, setIsDateRangeVisible, "calendar");

  const handleApply = () => {
    if (tempEndDate) {
      setEndDate(tempEndDate);
      setExpiryDate(getUnixTime(tempEndDate));
    }
    setToggleCalendar(false);
    setIsDateRangeVisible(true);
  };

  const handleCancel = () => {
    setTempEndDate(endDate); // Reset tempEndDate to the last applied date
    setToggleCalendar(false);
    setIsDateRangeVisible(true);
  };

  return (
    <div className={classes.root} style={style}>
      <div
        style={{
          left: toggleCalendar ? 179 : 0,
        }}
        className={classes.dateselector}
      >
        {isDateRangeVisible && endDate
          ? endDate.getTime() === 0 // Check if endDate is the epoch time
            ? "Selected date: N/A"
            : `Selected date: ${format(endDate, "dd MMM yyyy")}`
          : "Select Expiry Date"}
      </div>
      <div className={classes.buttonContainer} ref={buttonContainer}>
        <button
          className={clsx(classes.button, {
            [classes.isActive]: toggleCalendar,
          })}
          type="button"
          onClick={() => {
            setToggleCalendar(true);
            setIsDateRangeVisible(false);
          }}
        >
          <CalendarTodayOutlinedIcon style={{ height: 14, width: 14 }} />
        </button>
        <div
          className={clsx(classes.calendarContainer, {
            [classes.hidden]: !toggleCalendar,
          })}
        >
          <div className={classes.calendarWrapper}>
            <DateRange
              editableDateInputs={true}
              onChange={(item: any) => {
                const selectedDate = item.selection.endDate;
                if (selectedDate && selectedDate > new Date()) {
                  setTempEndDate(selectedDate);
                }
              }}
              // moveRangeOnFirstSelection={false}
              retainEndDateOnFirstSelection={false}
              ranges={[{ startDate, endDate: tempEndDate, key: "selection" }]}
              minDate={startDate}
              maxDate={addDays(startDate, 365)}
              focusedRange={focusedRange}
              onRangeFocusChange={(range: FocusedRange) => setFocusedRange(range)}
              staticRanges={[]} // Ensure the calendar does not reset
              inputRanges={[]} // Ensure no predefined ranges
            />

            <div className={classes.buttonGroup}>
              <Button
                variant="contained"
                onClick={handleCancel}
                className={classes.cancelButton}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleApply}
                className={classes.applyButton}
              >
                Apply
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExpirySelector;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      overflowY: "auto",
    },
    subscriptionRow: {
      display: "flex",
      flexDirection: "column",
      marginTop: "10px",
      padding: "1rem",
    },
    radioGroup: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    viewOnly: {
      color: Colors.Gray,
      pointerEvents: "none",
    },
    expiryContainer: {
      display: "flex",
      alignItems: "center",
      marginTop: "10px",
      gap: "16px",
    },
    expiryTextBox: {
      flex: 1,
    },
    expirySelector: {
      flexShrink: 0,  // Prevents button from shrinking
    },
    expiryLabel: {
      display: "flex",
      alignItems: "center",
      color: theme.palette.primary.light,
    },
    lineBreak: {
      width: "100%",
      border: `1px solid ${Colors.LightGray}`,
    },
    dateselector: {
      display: "flex",
      flexDirection: "row",
      textAlign: "left",
      width: "10rem",
      // padding: theme.spacing(0, 2),
      marginTop: theme.spacing(1),
      whiteSpace: "nowrap",
    },
    buttonContainer: {
      margin: "1rem 0",
      display: "flex",
      flexDirection: "row",
    },
    button: {
      display: "flex",
      color: theme.palette.primary.light,
      border: `1px solid ${theme.palette.primary.light}`,
      backgroundColor: "#FFFFFF",
      whiteSpace: "nowrap",
      cursor: "pointer",
      padding: "0 1rem",
      outline: "none",
      height: 30,
    },
    createUserButton: {
      border: `1px solid ${theme.palette.primary.light}`,
      backgroundColor: theme.palette.primary.light,
      padding: "0 1rem",
      color: Colors.White,
      height: 30,
      cursor: "pointer",
      outline: "none",
    },
    table: {
      minWidth: "100%",
      marginTop: "1rem",
    },
    columnText: {
      color: theme.palette.primary.light,
      fontWeight: 600,
      borderTop: "2px solid #DDD",
      borderBottom: "2px solid #DDD",
    },
    tableCell: {
      padding: "4px 16px",
      margin: 0,
      borderBottom: "2px solid #DDD",
      fontSize: 14,
    },
    companyInformation: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      width: "80%",
      cursor: "pointer",
    },
    tokenName: {
      position: "relative",
      right: "9px",
      color: theme.palette.primary.light,
      fontSize: 14,
    },
    role: {
      color: Colors.Black,
      fontSize: 14,
    },
    accessGroup: {
      color: Colors.Black,
      fontSize: 14,
    },
    userEmail: {
      color: theme.palette.primary.light,
    },
    userPhone: {
      color: Colors.Black,
    },
    comment: {
      height: "1.8rem",
      color: Colors.Gray,
      cursor: "pointer",
    },
    ellipsis: {
      cursor: "pointer",
      color: theme.palette.primary.light,
      height: "2rem",
      zIndex: 100,
    },
    dot: {
      backgroundColor: "LightGreen",
      height: ".6rem",
      width: ".6rem",
      borderRadius: "50%",
      position: "relative",
      right: "15px",
    },
    activeDot: {
      backgroundColor: Colors.Green,
    },
    inactiveDot: {
      backgroundColor: Colors.LightGray,
    },
    notPermitted: {
      opacity: 0.5,
      pointerEvents: "none",
    },

    isActive: {
      backgroundColor: theme.palette.primary.light,
      color: "#FFFFFF",
    },
    calendarContainer: {
      top: 8,
      zIndex: 15,
      position: "relative",
      [`@media (max-width: 1080px)`]: {
        position: "absolute",
        left: 0,
        right: 0,
        margin: "0 auto",
        width: "80%",
        maxWidth: "500px",
        display: "flex",
        justifyContent: "center",
        top: "50%",
        transform: "translateY(-50%)",
      },
    },
    hidden: {
      display: "none",
    },
    calendarWrapper: {
      backgroundColor: "#FFFFFF",
      padding: theme.spacing(2),
      borderRadius: theme.shape.borderRadius,
      boxShadow: theme.shadows[5],
    },
    buttonGroup: {
      display: "flex",
      justifyContent: "space-between",
      marginTop: theme.spacing(2),
    },
    cancelButton: {
      marginLeft: theme.spacing(2),
      marginBottom: theme.spacing(1),
    },
    applyButton: {
      marginRight: theme.spacing(2),
      marginBottom: theme.spacing(1),
    },
  })
);

