/* Issue fixed for github issue #35,issue #143,issue #152,issue #284 */
import { axiosCall, URL, Method } from "./config";
import { Device, Entity } from "features/accounts-page/components/types";
import {
  PostUserObject,
  PutUserObject,
  PostTenantObject,
  PutTenantObject
} from "app/types";
import { custAppId } from "custom/customizations";

export default {
  // ! user APIs
  //#region
  postNewUser: async (postUserObject: PostUserObject) => {
    const response = await axiosCall(
      Method.POST,
      `${URL()}/user`,
      postUserObject
    );

    return response;
  },
  deleteUser: async (userId: string, tenantId: string) => {
    const response = await axiosCall(Method.DELETE, `${URL()}/user`, {
      user_id: userId,
      tenant_id: tenantId //TenantId added in the api call. Github issue #35
    });

    return response;
  },
  putSelectedUser: async (putUserObject: PutUserObject) => {
    const response = await axiosCall(
      Method.PUT,
      `${URL()}/user`,
      putUserObject
    );

    return response;
  },
  // getUserProfile: async (userId: string) => {
  //   const response = await axiosCall(Method.POST, `${URL()}/user/profile`, {
  //     user_id: userId
  //   });

  //   return response.data.Item;
  // },
  //  Added new API for getting custom api app data for issue #284
  getUserProfile: async (userId: string) => {
    const response = await axiosCall(Method.POST, `${URL()}/user/custom_profile`, {
      user_id: userId,
      cust_app_id: custAppId
    });

    return response.data.Item;
  },
  disableUser: async (userId: string, tenantId: string) => {
    const response = await axiosCall(Method.PUT, `${URL()}/user/disable`, {
      user_id: userId,
      tenant_id: tenantId
    });

    return response;
  },
  enableUser: async (userId: string, tenantId: string) => {
    const response = await axiosCall(Method.PUT, `${URL()}/user/enable`, {
      user_id: userId,
      tenant_id: tenantId
    });

    return response;
  },
  getUserList: async (tenantId: string) => {
    const response = await axiosCall(Method.POST, `${URL()}/user/list`, {
      tenant_id: tenantId
    });

    return response.data;
  },
  retrieveGroupsInGroups: async (usersAssetsGroupIds: string[]) => {
    const response = await axiosCall(
      Method.POST,
      `${URL()}/user/groups_in_group`,
      {
        asset_group_ids: usersAssetsGroupIds
      }
    );

    return response.data;
  },
  //#endregion
  // ! tenant APIs
  //#region
  // getAllTenants: async () => {
  //   const response = await axiosCall(Method.GET, `${URL()}/tenant/alltenants`);
  //   return response.data.Items;
  // },
  //  Added new API for getting custom api app data for issue #284
  getAllAppTenants: async () => {
    const response = await axiosCall(Method.GET, `${URL()}/tenant/alltenants`);
    return response.data.Items;
  },

  getAllTenants: async () => {
    const response = await axiosCall(Method.POST, `${URL()}/tenant/all_custom_tenants`, {
      cust_app_id: custAppId
    });
    return response.data.Items;
  },
  // postTenant: async (postTenantObject: PostTenantObject) => {
  //   const response = await axiosCall(
  //     Method.POST,
  //     `${URL()}/tenant`,
  //     postTenantObject
  //   );

  //   return response;
  // },
  postTenant: async (postTenantObject: PostTenantObject) => {
    const response = await axiosCall(
      Method.POST,
      `${URL()}/tenant/create_custom_tenant`,
      postTenantObject
    );

    return response;
  },
  deleteTenant: async (tenantId: string) => {
    const response = await axiosCall(Method.DELETE, `${URL()}/tenant`, {
      tenant_id: tenantId
    });

    return response;
  },
  getTenantProfile: async (tenantId: string) => {
    const response = await axiosCall(Method.POST, `${URL()}/tenant/profile`, {
      tenant_id: tenantId
    });

    return response.data.Item;
  },
  putTenantProfile: async (putTenantObject: PutTenantObject) => {
    const response = await axiosCall(
      Method.PUT,
      `${URL()}/tenant`,
      putTenantObject
    );

    return response.data.Attributes;
  },
  getTenantColors: async (tenantId: string) => {
    const response = await axiosCall(
      Method.POST,
      `${URL()}/tenant/display_settings/available_colors`,
      {
        tenant_id: tenantId
      }
    );
    return response.data.Item;
  },
  setTenantColors: async (tenantId: string, colors: Array<string>) => {
    const response = await axiosCall(
      Method.PUT,
      `${URL()}/tenant/display_settings/available_colors`,
      {
        tenant_id: tenantId,
        available_colors: colors
      }
    );
    return response;
  },
  getActiveColor: async (tenantId: string) => {
    const response = await axiosCall(
      Method.POST,
      `${URL()}/tenant/display_settings/active_color`,
      {
        tenant_id: tenantId
      }
    );
    return response.data.Item;
  },
  setActiveColor: async (tenantId: string, activeColor: string) => {
    const response = await axiosCall(
      Method.PUT,
      `${URL()}/tenant/display_settings/active_color`,
      {
        tenant_id: tenantId,
        active_color: activeColor
      }
    );
    return response;
  },
  getAllAttributes: async (tenantId: string) => {
    const response = await axiosCall(
      Method.POST,
      `${URL()}/tenant/display_settings/logo`,
      {
        tenant_id: tenantId
      }
    );
    return response.data.Item;
  },
  setLogoAndGetAllAttributes: async (
    tenantId: string,
    relativeLogoUrl: string
  ) => {
    const response = await axiosCall(
      Method.PUT,
      `${URL()}/tenant/display_settings/logo`,
      {
        tenant_id: tenantId,
        logo_url: relativeLogoUrl
      }
    );
    return response.data.Attributes.display_settings;
  },
  setDisplayName: async (tenantId: string, displayName: string) => {
    const response = await axiosCall(
      Method.PUT,
      `${URL()}/tenant/display_settings/name`,
      {
        tenant_id: tenantId,
        display_name: displayName
      }
    );
    return response.data.Attributes.display_settings;
  },
  getSuperAdminAccountId: async () => {
    const response = await axiosCall(
      Method.GET,
      `${URL()}/tenant/super_tenant`
    );
    return response;
  },
  updateAPISubscription: async ({
    tenantId,
    api_subscription,
  }: UpdateAPISubscriptionProps) => {
    // update_device_subscription
    const response = await axiosCall(Method.POST, `${URL()}/tenant/api_subscription`, {
      tenant_id: tenantId,
      api_subscription,
    });
    return response.data;
  },
  // Adding new API to creat API token for issue #143
  createToken: async (
    tenantId: string,
    userId: string,
    tokenName: string,
    tokenExpiry: string,
    tokenAccess: any,
    accessGroups: any
  ) => {
    const response = await axiosCall(Method.POST, `${URL()}/tenant/api_token`, {
      tenant_id: tenantId,
      user_id: userId,
      token_name: tokenName,
      token_expiry: tokenExpiry,
      token_access: tokenAccess,
      access_groups: accessGroups
    });
    return response.data.Item;
  },
  // Adding new API to retireve all API tokens for issue #143
  getAllTokens: async (tenantId: string) => {
    const response = await axiosCall(
      Method.POST,
      `${URL()}/tenant/api_tokens`,
      {
        tenant_id: tenantId
      }
    );
    return response.data;
  },
  // Adding new API to retireve all data connectors for issue #294
  getAllConnectors: async (tenantId: string) => {
    const response = await axiosCall(
      Method.POST,
      `${URL()}/dataconnector/get_alldataconnector`,
      {
        tenant_id: tenantId
      }
    );
    return response.data;
  },
  // Adding new API to update token for issue #143
  updateToken: async (
    tenantId: string,
    tokenId: string,
    tokenName: string,
    tokenExpiry: string,
    tokenAccess: any,
    accessGroups: any
  ) => {
    const response = await axiosCall(Method.PUT, `${URL()}/tenant/api_token`, {
      tenant_id: tenantId,
      token_id: tokenId,
      token_name: tokenName,
      token_expiry: tokenExpiry,
      token_access: tokenAccess,
      access_groups: accessGroups
    });
    return response.data;
  },
  // Adding new API to delete token for issue #143
  deleteToken: async (tenantId: string, tokenId: string) => {
    const response = await axiosCall(
      Method.DELETE,
      `${URL()}/tenant/api_token`,
      {
        tenant_id: tenantId,
        token_id: tokenId
      }
    );
    return response;
  },
  // Adding new API to activate/deactivate token for issue #143
  activateDeactivateToken: async (
    tenantId: string,
    tokenId: string,
    tokenActive: string
  ) => {
    const response = await axiosCall(
      Method.POST,
      `${URL()}/tenant/api_token_status`,
      {
        tenant_id: tenantId,
        token_id: tokenId,
        token_active: tokenActive
      }
    );
    return response;
  },
  // Adding new API to creat API token for issue #320
  createConnector: async (
    tenantId: string,
    connectorName: string,
    connectorType: number,
    deviceList: any,
    consumerKey: string,
    consumerPK: string,
    consumerSecret: string,
    endPoint: string,
    username: string,
    password: string,
    scheduleHrs: string,
    scheduleDays: string,
    scheduleTz: string,
    startDate: string,
    entityList: Entity[]
  ) => {
    const response = await axiosCall(Method.POST, `${URL()}/dataconnector/create_dataconnector`, {
      tenant_id: tenantId,
      connector_name: connectorName,
      connector_type: connectorType,
      device_list: deviceList,
      c_username: username,
      c_pwd: password,
      c_pk: consumerPK,
      cons_key: consumerKey,
      cons_sec: consumerSecret,
      endpoint: endPoint,
      schedule_hours: scheduleHrs,
      schedule_days: scheduleDays,
      schedule_tz: scheduleTz,
      next_delivery: startDate,
      entity: entityList
    });
    return response.data.Item;
  },
  // Adding new API to creat API token for issue #320
  updateConnector: async (
    tenantId: string,
    connectorName: string,
    connectorID: string,
    connectorType: number,
    deviceList: any,
    consumerKey: string,
    consumerPK: string,
    consumerSecret: string,
    endPoint: string,
    username: string,
    password: string,
    scheduleHrs: string,
    scheduleDays: string,
    scheduleTz: string,
    startDate: string,
    entityList: Entity[]
  ) => {
    const response = await axiosCall(Method.POST, `${URL()}/dataconnector/update_dataconnector`, {
      tenant_id: tenantId,
      connector_name: connectorName,
      connector_type: connectorType,
      connector_id: connectorID,
      device_list: deviceList,
      c_username: username,
      c_pwd: password,
      c_pk: consumerPK,
      cons_key: consumerKey,
      cons_sec: consumerSecret,
      endpoint: endPoint,
      schedule_hours: scheduleHrs,
      schedule_days: scheduleDays,
      schedule_tz: scheduleTz,
      next_delivery: startDate,
      entity: entityList
    });
    return response.data.Item;
  },
  // Adding new API to delete token for issue #320
  deleteConnector: async (tenantId: string, connectorId: string) => {
    const response = await axiosCall(
      Method.POST,
      `${URL()}/dataconnector/delete_dataconnector`,
      {
        tenant_id: tenantId,
        connector_id: connectorId
      }
    );
    return response;
  },
  //Adding new api to send otp for issue #152
  sendOtp: async (userId: string, phoneMobile: string) => {
    const response = await axiosCall(Method.POST, `${URL()}/user/send_otp`, {
      user_id: userId,
      phone_mobile: phoneMobile
    });
    return response.data;
  },
  //Adding new api to verify otp for issue #152
  verifyOtp: async (userId: string, phoneMobile: string, userOtp: string) => {
    const response = await axiosCall(Method.POST, `${URL()}/user/verify_otp`, {
      user_id: userId,
      phone_mobile: phoneMobile,
      user_otp: userOtp
    });
    return response.data;
  }
  //#endregion
};

interface UpdateAPISubscriptionProps {
  tenantId: string;
  api_subscription: {
    expiry: string;
    level: number;
    last_updated: string;
  };
}
